define([], function() {

  const deliveryInfoAliases = {
    'germany': ['Deutschland'],
    'united-kingdom': ['Großbritannien', 'uk', 'united kingdom', 'england'],
    'united-states': ['usa', 'united states', 'america'],
    'usa': ['usa', 'united states', 'america']
  };

  return deliveryInfoAliases;

});
